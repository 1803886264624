import Highway from '@dogstudio/highway';
import communeDropdown from "../../communeDropdown";
import communeCalendar from "../../communeCalendar";
import parallaxImg from "../../parallaxImg";
import scrollYears from "../../scrollYears";

export default class SingleCommuneRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        var communeBody = document.querySelector('.commune-body');

        if (communeBody) {
            var dropDown = new communeDropdown();
            var button = document.querySelector('.read-more-btn');

            button.addEventListener('click', function(e) {
                e.preventDefault();
                dropDown.dropDownUp();
            });
        };

        var calendar = new communeCalendar();

        // function is_touch_enabled() {
        //     return ('ontouchstart' in window) ||
        //         (navigator.maxTouchPoints > 0) ||
        //         (navigator.msMaxTouchPoints > 0);
        // };
        // if (!is_touch_enabled()) {
        //     calendar.yearsContainer.addEventListener('mousemove', function(e) {
        //         calendar.slideYear(e.pageX - this.getBoundingClientRect().left);
        //     });
        // } else {
        //     var yearContainer = document.querySelector('.years-container');
        //     yearContainer.classList.remove('overflow-hidden');
        //     yearContainer.classList.add('overflow-scroll');
        // };
        calendar.buttons.forEach(button => {
            button.addEventListener('click', function(e) {
                calendar.buttons.forEach(item => {
                    item.classList.remove('bg-blue-500');
                    item.classList.add('bg-blue-300');
                });
                console.log(button);
                this.classList.add('bg-blue-500');
                this.classList.remove('bg-blue-300');
                var year = button.innerHTML;
                calendar.selectYear(year);
            })
        });

        new parallaxImg();
    }
    onLeave() {

    }
    onEnterCompleted() {
        new parallaxImg();
        new scrollYears();
    }
    onLeaveCompleted() {

    }
}