import gsap from 'gsap';
import { TweenMax, Expo } from 'gsap';

export default class parallaxImg {
    constructor() {
        var fgImage = document.querySelector('.circle-fg img'),
            bgImage = document.querySelector('.circle-bg img');

        if (fgImage) {
            window.addEventListener('scroll', function(e) {
                var scrollY = window.scrollY;
                fgImage.style.transform = 'translate3d(0,' + scrollY / 10 + 'px,0)';
                bgImage.style.transform = 'translate3d(0,' + scrollY / 15 + 'px,0)';
            });
        };
    }
}