import Cookies from 'js-cookie';

export default class CookieAgreed {
  constructor() {

    if (Cookies.get('cookie-agreed') != true && Cookies.get('cookie-refused') != true){
      document.getElementById('cookie-warning').classList.remove('hidden');
    }else{

      document.getElementById('cookie-warning').classList.add('hidden');

      if(Cookies.get('cookie-refused')) {
        this.deleteGoogle()
      }
    }
    document.getElementById('consent-given-link').addEventListener('click', function(event){
      event.preventDefault();
      event.stopImmediatePropagation();
      this.consentGiven();
      return false;
    }.bind(this));
    
    document.getElementById('consent-notgiven-link').addEventListener('click', function(event){
      event.preventDefault();
      event.stopImmediatePropagation();
      this.consentNotGiven();
      return false;
    }.bind(this));
  }

  consentGiven(){
    Cookies.set('cookie-agreed', 1, {expires: 365} );
    document.getElementById('cookie-warning').classList.add('hidden');
    var event = new Event('consent-given');
    document.dispatchEvent(event);
  }
  consentNotGiven(){
    Cookies.set('cookie-refused', 1, {expires: 365} );
    document.getElementById('cookie-warning').classList.add('hidden');
    this.deleteGoogle()
  }
  deleteGoogle() {
    if (window.ga) window.ga('remove');
    // Remove the default cookies
    // _ga is used to distinguish users.
    Cookies.remove('_ga', { path: '/', domain: document.domain });
    // _gid is used to distinguish users.
    Cookies.remove('_gid', { path: '/', domain: document.domain });
    // _gat is used to throttle request rate.
    Cookies.remove('_gat', { path: '/', domain: document.domain });
    Cookies.remove('_ga_KMT6SG95T4', { path: '/', domain: document.domain });
  }

}
