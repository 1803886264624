import "./scss/app.scss";

import AdminTabs from "./js/adminTabs";
import CookieAgreed from "./js/cookieAgreed";
import Highway from "@dogstudio/highway";
import "alpinejs";

// Renderers
import HomeRenderer from "./js/highway/renderer/home-renderer";
import SingleCommuneRenderer from "./js/highway/renderer/single-commune-renderer";
import CommunesRenderer from "./js/highway/renderer/communes-renderer";
import ConceptRenderer from "./js/highway/renderer/concept-renderer";
import EntreprisesRenderer from "./js/highway/renderer/entreprises-renderer";
import DefaultRenderer from "./js/highway/renderer/default-renderer";

//Transitions
import Fade from "./js/highway/transition/fade";
import gsap from "gsap/gsap-core";
import { Expo } from "gsap";

//Admin
new AdminTabs();

document.addEventListener("DOMContentLoaded", function () {
  document.querySelector("[data-drupal-messages-fallback]").remove();
});

//GDPR
document.addEventListener("consent-given", function () {
  console.log("consent given");
  ga("create", "UA-51237255-1", {"cookieDomain":"auto"});ga("set", "anonymizeIp", true);ga("send", "pageview");
});
document.addEventListener("consent-refused", function () {
  console.log("consent refused");
  window.ga('remove')
});
new CookieAgreed();

// Header
window.addEventListener("scroll", function (e) {
  var scrollY = window.scrollY;
  var winH = window.innerHeight;
  var mobile = window.innerWidth < 1024;

  if (scrollY > winH / 3) {
    if (!mobile) {
      gsap.to(".main-nav li", {
        y: "-20px",
        duration: 1,
        stagger: -0.05,
        ease: Expo.easeOut,
      });
    }
    gsap.to(".header-logo", {
      y: "-20px",
      scale: 0.75,
      duration: 1,
      ease: Expo.easeOut,
    });
    gsap.to(".header-bg", { autoAlpha: 1, duration: 2, ease: Expo.easeOut });
  } else {
    gsap.to(".main-nav li", {
      y: 0,
      duration: 1,
      stagger: -0.05,
      ease: Expo.easeOut,
    });
    gsap.to(".header-logo", {
      y: 0,
      scale: 1,
      duration: 1,
      ease: Expo.easeOut,
    });
    gsap.to(".header-bg", { autoAlpha: 0, duration: 2, ease: Expo.easeOut });
  }
});

// Mobile Nav
var body = document.querySelector("body");
var navIsOpen = false;
var burger = document.querySelector(".burger");
var navOpenAnim = gsap.timeline({ paused: true });
var navCloseAnim = gsap.timeline({ paused: true });

burger.addEventListener("click", function () {
  if (!navIsOpen) {
    body.classList.add("overflow-hidden");
    navOpenAnim
      .to(".burger-bars", { autoAlpha: 0 })
      .to(".burger-x", { autoAlpha: 1, display: "flex" }, 0.5)
      .to(".main-nav", { autoAlpha: 1, display: "flex" }, 0.5)
      .fromTo(
        ".main-nav li",
        { autoAlpha: 0, yPercent: 50, rotation: 2.5, stagger: 0.1 },
        { autoAlpha: 1, yPercent: 0, rotation: 0, stagger: 0.1 }
      )
      .call(function () {
        navIsOpen = true;
      });

    navOpenAnim.play();
  } else {
    body.classList.remove("overflow-hidden");
    navCloseAnim
      .to(".burger-x", { autoAlpha: 0, display: "none" })
      .to(".burger-bars", { autoAlpha: 1 }, 0.5)
      .to(".main-nav", { autoAlpha: 0, display: "none" }, 0.5)
      .set(".main-nav, .main-nav li", { clearProps: "all" })
      .call(function () {
        navIsOpen = false;
      });

    navCloseAnim.play();
  }
});

// bubble

var bubbleSize;

function sizedBubble(winWidth, winHeight) {
  bubbleSize = Math.sqrt(Math.pow(winWidth, 2) + Math.pow(winHeight, 2)) * 2;

  gsap.set(".bubble", {
    width: bubbleSize,
    height: bubbleSize,
    top: winHeight / 2 - bubbleSize / 2,
    left: winWidth / 2 - bubbleSize / 2,
    scale: 0,
  });
}

sizedBubble(window.innerWidth, window.innerHeight);

window.addEventListener("resize", function () {
  sizedBubble(window.innerWidth, window.innerHeight);
});

window.addEventListener("mousemove", function (e) {
  var posX = e.clientX - window.innerWidth / 2;
  var posY = e.clientY - window.innerHeight / 2;

  gsap.to(".bubble", { x: posX, y: posY });
});

// // Main Nav Bubble

// var navItem = document.querySelectorAll('.main-nav a');
// console.log();

// navItem.forEach(function() {
//     var thisBubble = this.parentElement.previousElementSibling;

//     this.addEventListener('mouseover', function() {
//         console.log(thisBubble);
//     });
// });

/***
 * Highway
 */

// Call Highway.Core once.
// Store it in a variable to use events
var H = new Highway.Core({
  renderers: {
    homepage: HomeRenderer,
    singleCommune: SingleCommuneRenderer,
    communes: CommunesRenderer,
    concept: ConceptRenderer,
    entreprises: EntreprisesRenderer,
    default: DefaultRenderer,
  },
  transitions: {
    name: Fade,
    default: Fade,
  },
});

// Detach admin links
if (document.getElementById("admin-tools")) {
  H.detach(document.querySelectorAll("a"));
}

//Listen Events

H.on("NAVIGATE_END", ({ to }) => {
  // Call Quicklink
  // listen();
});

H.on("NAVIGATE_IN", ({ to }) => {
  activeLink();
});

window.addEventListener("load", () => {
  // Call Quicklink
  // listen();
  activeLink();
});

function activeLink() {
  const links = document.querySelectorAll(".main-nav a");

  if (navIsOpen) {
    burger.click();
  }
  // Check Active Link
  for (let i = 0; i < links.length; i++) {
    const link = links[i];

    // Clean class
    // link.classList.remove('is-active');
    link.nextElementSibling.classList.remove("scale-100");

    // Active link
    if (link.href === location.href) {
      // link.classList.add('is-active');
      link.nextElementSibling.classList.add("scale-100");
    }
  }
}
