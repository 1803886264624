import Highway from '@dogstudio/highway';

export default class DefaultRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        // var mainNavItem = document.querySelectorAll('.main-nav a');

        // mainNavItem.forEach((link, i) => {
        //     //var bubble = link.parentElement.previousElementSibling;
        //     //console.log(bubble);

        //     link.addEventListener('mouseover', function() {
        //         var thisBubble = this.parentElement.previousElementSibling;
        //         thisBubble.style.setProperty('--transform-scale-x', 1);
        //         thisBubble.style.setProperty('--transform-scale-y', 1);
        //     });

        //     link.addEventListener('focus', function() {
        //         var thisBubble = this.parentElement.previousElementSibling;
        //         thisBubble.style.setProperty('--transform-scale-x', 1);
        //         thisBubble.style.setProperty('--transform-scale-y', 1);
        //     });

        //     link.addEventListener('mouseleave', function() {
        //         var thisBubble = this.parentElement.previousElementSibling;
        //         thisBubble.style.setProperty('--transform-scale-x', 0);
        //         thisBubble.style.setProperty('--transform-scale-y', 0);
        //     });

        //     link.addEventListener('blur', function() {
        //         var thisBubble = this.parentElement.previousElementSibling;
        //         thisBubble.style.setProperty('--transform-scale-x', 0);
        //         thisBubble.style.setProperty('--transform-scale-y', 0);
        //     });
        // });
    }
    onLeave() {

    }
    onEnterCompleted() {

    }
    onLeaveCompleted() {

    }
}