import Highway from '@dogstudio/highway';
import parallaxImg from "../../parallaxImg";

export default class HomeRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        new parallaxImg();

        var lastNewsReadMore = document.querySelectorAll('.last-news-item .read-more');

        lastNewsReadMore.forEach(button => {
            button.addEventListener('mouseover', function() {
                var thisLastNewsItem = this.parentElement.parentElement;
                thisLastNewsItem.classList.add('shadow-sand-deep');
                thisLastNewsItem.classList.add('-translate-y-8');
            });

            button.addEventListener('focus', function() {
                var thisLastNewsItem = this.parentElement.parentElement;
                thisLastNewsItem.classList.add('shadow-sand-deep');
                thisLastNewsItem.classList.add('-translate-y-8');
            });

            button.addEventListener('mouseleave', function() {
                var thisLastNewsItem = this.parentElement.parentElement;
                thisLastNewsItem.classList.remove('shadow-sand-deep');
                thisLastNewsItem.classList.remove('-translate-y-8');
            });

            button.addEventListener('blur', function() {
                var thisLastNewsItem = this.parentElement.parentElement;
                thisLastNewsItem.classList.remove('shadow-sand-deep');
                thisLastNewsItem.classList.remove('-translate-y-8');
            });
        });
    }
    onLeave() {

    }
    onEnterCompleted() {
        new parallaxImg();
    }
    onLeaveCompleted() {

    }
}