import Highway from '@dogstudio/highway';
import parallaxImg from "../../parallaxImg";

export default class ConceptRenderer extends Highway.Renderer {
    // Hooks/methods
    onEnter() {
        new parallaxImg();
    }
    onLeave() {

    }
    onEnterCompleted() {
        new parallaxImg();
    }
    onLeaveCompleted() {

    }
}