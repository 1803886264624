import gsap from 'gsap';
import { TweenMax, Expo } from 'gsap';

export default class communeDropdown {
    constructor() {
        this.isOpen = false;
        this.dropDown = document.querySelector('.commune-body');
        this.dropDownCloseHeight = this.dropDown.offsetHeight;
        this.button = document.querySelector('.read-more-btn');
        this.ReadMore = this.button.getAttribute('data-more');
        this.ReadLess = this.button.getAttribute('data-less');
    }

    dropDownUp() {
        this.dropDownOpenHeight = document.querySelector('.commune-body>div').offsetHeight;
        if (this.isOpen == false) {
            console.log('openning');
            gsap.to(this.dropDown, { height: this.dropDownOpenHeight, duration: 1, ease: Expo.easeInOut });
            gsap.to('.commune-body__gradient', { scaleY: 0, duration: 1, z: 1, ease: Expo.easeInOut });
            gsap.to(this.button.querySelector('i'), { rotate: 180, duration: 1, ease: Expo.easeInOut });
            //gsap.to('.commune-header-info', { marginTop: this.dropDownOpenHeight, duration: .5, ease: Power4.easeOut });
            this.button.querySelector('span').innerHTML = this.ReadLess;

            this.isOpen = true;
        } else {
            console.log('closing');
            gsap.to(this.dropDown, { height: this.dropDownCloseHeight, duration: 1, ease: Expo.easeInOut });
            gsap.to('.commune-body__gradient', { scaleY: 1, duration: 1, z: 1, ease: Expo.easeInOut });
            gsap.to(this.button.querySelector('i'), { rotate: 0, duration: 1, ease: Expo.easeInOut });
            //gsap.to('.commune-header-info', { marginTop: this.dropDownCloseHeight, duration: .5, ease: Power4.easeOut });
            this.button.querySelector('span').innerHTML = this.ReadMore;
            this.isOpen = false;
        }
    }
}