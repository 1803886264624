import gsap from 'gsap';
import { TweenMax, Power4 } from 'gsap';

export default class communeCalendar {
    constructor() {
        this.yearsContainer = document.querySelector('.years-container');
        this.yearsFull = document.querySelector('.years-full');
        this.yearActive = 2020;
        this.buttons = document.querySelectorAll('.years-full button');
        this.curtain = document.querySelector('.years-curtain');
    }

    // slideYear(cursorPosition) {
    //     this.yearsContainerW = this.yearsContainer.offsetWidth;
    //     this.yearsFullW = this.yearsFull.offsetWidth;
    //     this.hiddenPartW = (this.yearsFullW - this.yearsContainerW);
    //     this.cursorPosInPercent = (cursorPosition / this.yearsContainerW) * 100;
    //     this.percentOfHiddenPart = (this.hiddenPartW / 100) * this.cursorPosInPercent;

    //     gsap.to(this.yearsFull, { x: -this.percentOfHiddenPart, duration: 1, ease: Power4.easeOut });
    // }

    selectYear(year) {
        this.yearSelected = document.querySelector('.year-calendar--' + year);

        var showCurtain = gsap.to(this.curtain, { autoAlpha: 1, display: 'block', duration: 1, ease: Power4.easeOut });
        var hideCurtain = gsap.to(this.curtain, { autoAlpha: 0, display: 'none', duration: 1, ease: Power4.easeOut, delay: 1 });

        showCurtain.eventCallback('onComplete', this.hideAll);
        hideCurtain.eventCallback('onStart', this.showSelected, [this.yearSelected]);

        this.yearActive = year;
    }

    hideAll() {
        this.allYearsCalendar = document.querySelectorAll('.year-calendar');
        console.log('hide all');
        this.allYearsCalendar.forEach(y => {
            y.classList.remove('block');
            y.classList.add('hidden');
        });
    }

    showSelected(year) {
        console.log('show selected');
        year.classList.remove('hidden');
        year.classList.add('block');
    }
}