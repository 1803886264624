import 'overlayscrollbars/css/OverlayScrollbars.css';
import OverlayScrollbars from 'overlayscrollbars';

export default class scrollYears {

    constructor() {

        var instances = OverlayScrollbars(document.querySelectorAll(".years-container"), {
            scrollbars: {
                visibility: 'auto',
                autoHide: 'move',
                autoHideDelay: 500,
            },
        });


    }

}