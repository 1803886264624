import Highway from '@dogstudio/highway';
import { gsap } from "gsap";
// import { TweenMax, Power4 } from 'gsap';
import { TweenMax, Elastic, Power4 } from 'gsap';

export default class Fade extends Highway.Transition {

    out({ from, trigger, done }) {
        var outAnim = gsap.timeline({ paused: true, onComplete: done });

        outAnim.to('.bubble', { scale: 1, duration: 1, ease: Power4.easeInOut })
            .to(from, { duration: .1, opacity: 0, ease: Power4.easeInOut, onComplete: done })
        outAnim.play();
    }

    in ({ from, to, trigger, done }) {
        from.remove();
        var inAnim = gsap.timeline({ paused: true, onComplete: done });

        window.scrollTo(0, 0);
        inAnim.to('.bubble', { opacity: 0, duration: .5, ease: Power4.easeOut })
            .fromTo(to, { opacity: 0 }, { duration: .5, opacity: 1 }, '-=.25')
            .fromTo(to.querySelector('h1'), { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: .75, ease: Power4.easeOut }, '-=.25')
            .fromTo(to.querySelector('.circle-bg'), { scale: .9, opacity: 0 }, { scale: 1, opacity: 1, duration: 1, ease: Power4.easeOut }, '-=.5')
            .fromTo(to.querySelector('.circle-fg'), { scale: .8, opacity: 0 }, { scale: 1, opacity: 1, duration: 1, ease: Power4.easeOut }, '-=.95')
            .set('.bubble', { scale: 0, opacity: 1 });

        inAnim.play();


    }

}